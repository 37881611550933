<template src="./selectInfo.html"></template>

<script>
export default {
  name: "selectInfo",
  data: () => ({
    order:{

      email:'',
      phone:'',
      fio:'',

      event_id:   0,
      show_id:    0,
      culture_id: 0,

      link: false,
      payment_type: 0,

      seats:[],

      show_name:'Спектакль не выбран',

      total:{
        price:0,
        count:0,
      },
      date: 'Сегодня',
    },
    helpData:[],
    seats:[],
    sectorsHall:[
      {id:1,name:"Партер"},
      {id:2,name:"Амфитеатр центр"},
      {id:3,name:"Бельэтаж центр"},
      {id:4,name:"Ложа бенуар (правая)"},
      {id:5,name:"Балкон центр"},
      {id:6,name:"Ложа бенуар (левая)"},
      {id:7,name:"Балкон (правый)"},
      {id:8,name:"Балкон (левый)"},
      {id:9,name:"Амфитеатр (правый)"},
      {id:10,name:"Амфитеатр (левый)"},
      {id:11,name:"Бельэтаж (правый)"},
      {id:12,name:"Бельэтаж (левый)"},
      {id:13,name:"Сектор I"},
      {id:14,name:"Сектор II"},
      {id:15,name:"Сектор III"},
      {id:16,name:"Сектор IV"},
      {id:17,name:"Сектор V"},
      {id:18,name:"Сектор VI"},
      {id:19,name:"Сектор VII"},
      {id:20,name:"Сектор VIII"},
      {id:21,name:"Амфитеатр"},
      {id:22,name:"Входной"},
      {id:23,name:"Бельэтаж"},
      {id:24,name:"Партер I"},
      {id:25,name:"Партер II"},
      {id:26,name:"Ложа правая"},
      {id:27,name:"Ложа левая"}
    ],
  }),
  methods:{
    helpDataCreate(data,seats,order){

      console.log(data, seats, order)

      this.seats = seats;
      //this.helpData.splice(0,this.helpData.length)
      this.helpData = [];
      this.order = order;

      let selected = [];
      data.forEach((item)=>{
        let seat = this.seats.find(el=>el.id === item.id)
        seat.price = item.price;
        selected.push(seat);
      })
      console.log(selected)
      selected.forEach((item)=>{
        let finded = this.helpData.find(el=>el.sector_id === item.sector_id);
        if(finded === undefined){
          let el = {sector_id:item.sector_id,data:[item]}
          this.helpData.push(el)
        }else{
          finded.data.push(item)
        }
      })

      console.log(this.helpData)

      //id
      //sector_id - партер/амфитеатр
      //row - ряд
      // chair - место
      // price - место
      // zone - ценовая зона
    },
  }
}
</script>

<style scoped>

</style>
