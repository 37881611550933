<template src="./PixiHallContent.html"></template>

<script>
import PixiHall from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent/PixiHall/PixiHall";
import PixiTest from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent/PixiTest/PixiTest";
import selectInfo from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent/selectInfo/selectInfo";
import {mapGetters} from "vuex";

export default {
  name: "PixiHallContent",
  components:{PixiHall,PixiTest,selectInfo},

  props:{

    showCaptionInMobile: {type: Boolean, default:false},

    // не делал, но нужно вернуться и доделать и инициализацию и старт с ними
    show_id:    { type: Number,   default:0},
    event_id:   { type: Number,   default:0},
    culture_id: { type: Number,   default:0},
    theater_id: { type: Number,   default:0},
    mo_id:      { type: Number,   default:0},

  },

  data: () => ({
    typeTouch:"",
    helpData:[],
    //DB
    flags:{
      hallShow:false,
      idsLoaded: false,
      showheader: "Начинаем",
      eventloaded:false,
      pixiHallReady:false
    },
    empty_id_pack:{
      show_id:    0,
      event_id:   0,
      culture_id: 0,
      theater:    0,
      mo_id:      0,
    },
    id_pack:{
      show_id:    0,
      event_id:   0,
      culture_id: 0,
      theater:    0,
      mo_id:      0,
    },

    order:{

      email:'',
      phone:'',
      fio:'',

      event_id:   0,
      show_id:    0,
      culture_id: 0,

      link: false,
      payment_type: 0,

      seats:[],

      show_name:'Спектакль не выбран',

      total:{
        price:0,
        count:0,
      },
      date: 'Сегодня',
    },
    //
    //
    seatRaw:{},
    //
    //
    emptyHallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig
    hallConfig:{
      additional:{
        monger_id:0,
        monger_mode: "show_all",
        zone_n:0,
        seat_status_id:0
      },
      base:{
        event_id:272,
        mode:"event",
        type:"user",
        view:"sale"
      },
      options:{
        armour:false,
        discount:false,
        puskin:false,
        zone:true
      }
    },
  }),
  computed:{
    ...mapGetters([
      "getColorZones",
      "getColorFrame"
    ])
    //TODO передать или вывести если есть в PixiHall получаемые данные о цветовых зонах, сейчас там заглушка
  },

  created(){
    console.log('PHC created');
  },

  mounted() {
    //this.from_parentGive();
    this.$emit('onMountedPHC')
  },
  methods:{
    pixiLoadedStatus(type){
      switch(type){
        case "start":
          this.flags.pixiHallReady = false;
        break;
        case "loaded":
          this.flags.pixiHallReady = true;
        break;
      }
    },
    updateMode(id){
      this.$refs.PixiHall.seatColorZone(id);
      // this.$refs.PixiTest.seatColorZone(id); //TODO DB
    },
    eventloadedFlag(data){//TODO проверить надобность флага
      this.flags.eventloaded = data;
      // прокидываем выше событие
      this.$emit('onEventLoad',true);

    },

    id_stak(IDs){
      this.flags.idsLoaded = false;
      this.HallConfig = Object.assign({}, this.emptyHallConfig);
      this.id_pack = Object.assign({}, IDs);
      this.flags.idsLoaded = true;
      if(this.id_pack.event_id !== 0){
        this.flags.showheader = "Подключаемся";
        this.loadSeatsData();
      }else{
        this.flags.showheader = "Данный сеанс не обнаружен";
      }
    },

    seatToOrder(seats, last_seat){

      this.$store.commit(
          'setSeatsData',
          JSON.parse(JSON.stringify(seats))
      );
      this.$store.commit(
          'createSeatInfo',
          this.seatRaw.data.seats
      );

      if (!(last_seat==undefined)){
        if (last_seat.selected){
          this.$emit('onSeatSelected', last_seat.id);
        }
      }

    },

    getPricesColors(dataPrices,dataPrice,dataZoneCount){
      let price = [];
      let prices = dataPrice.split('/');
      for (let i = 0; i<dataZoneCount; i++){
        let t = {};
        t.id = dataPrices.find(el => el.price_s === prices[i]).zone;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];
        price.push(t);
      }
      return price;
    },
    //create Data
    loadSeatsData(){
      switch(this.flags.pixiHallReady){
        case true:
          this.$refs.PixiHall.сastling("load")
          // this.$refs.PixiTest.сastling("load")//TODO DB
          this.flags.pixiHallReady = false;
        break;
        case false:
          this.$refs.PixiHall.startToPrint("pre");
          // this.$refs.PixiTest.startToPrint("pre");//TODO DB
          this.flags.pixiHallReady = false;
        break;
      }
      let pr = this.$store.state.api.getEventInet(this.id_pack.event_id, this.id_pack.mo_id);

      pr.then( data =>{
        this.flags.showheader = "Открываем";
        this.seatRaw.b = true;
        this.seatRaw.data = data;
        this.flags.showheader =  data.show.name +' ('+ data.show.age+') '+ data.event.timedate;
        this.seatRaw.getPricesColors = this.getPricesColors(data.prices,data.event.prices,data.price_map.zone_count);

        this.$refs.PixiHall.createSeatData(this.seatRaw);
        // this.$refs.PixiTest.createSeatData(this.seatRaw);//TODO DB

        //console.log(data);
        // подгружаем скидки в профиль
        this.$store.commit(
          'setDiscounts',
          JSON.parse(JSON.stringify(data.discount))
        );

        this.order.culture_id = this.id_pack.culture_id;
        this.order.event_id   = this.id_pack.event_id;
        this.order.show_id    = this.id_pack.show_id;
        this.order.show_name  = data.show.name;
        this.order.date       = data.event.timedate;

      })
    },
    onShowLoader(){
      this.flags.hallShow = false;
    },
    onReadyToShow(){
      this.flags.hallShow = true;
    },
    reLoad(){
      console.log("reLoad " + this.flags.hallShow)
      this.flags.hallShow = false;
      this.$refs.PixiHall.startToPrint("reCreate");
      // this.$refs.PixiTest.startToPrint("reCreate");//TODO DB
    },
    reCenter(){
      this.$refs.PixiHall.containerToCenter();
      // this.$refs.PixiTest.containerToCenter();//TODO DB
    },
    touchLog(data){
      this.typeTouch = data;
    }
  },
}
</script>

<style scoped>
#hallArea{
  overflow: hidden;
  position: relative;
  user-select: none;
}
#hallArea #selectInfo{
  position: absolute;
  background: #1e1e1e;
  z-index: 5;
  top:1px;
  right:1px;
  width: 280px;
  box-shadow: 0 0 10px 2px #ccc;
  max-height: 100%;
  overflow-y: auto;
}
.loader-gif{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  max-width: 200px;
  z-index: 2225;
}
</style>
