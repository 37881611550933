<template src="./PixiHall.html"></template>
<script>

import hallBase from '@/assets/json/pixiHallDemo.json';
import { Renderer, Texture, Sprite, Text, TextStyle } from 'pixi.js';
import { Viewport } from 'pixi-viewport'
import { Ticker } from '@pixi/ticker';
import { Graphics } from "@pixi/graphics";
import { mapGetters } from "vuex";

export default {

  name: "PixiHall",

  data: () => ({
    event_raw:{},
    flags:{
      viewportLoaded:false,
      baseCreated:false,
    },
    spritesTexture: {
      preloader: Texture.from('/img/preloader1.png'),
      texture: Texture.from('/img/chair.svg'),
      Chair: Texture.from('/img/chairN1.svg'),
      ChairSale: Texture.from('/img/chairN1-sale.svg'),
      ChairSale2: Texture.from('/img/chairN1-sale2.svg'),
      ChairEmpty: Texture.from('/img/chairN1-empty.svg'),
      background: null,
    },
    base: {
      ticker: new Ticker,
      app: null,
      viewport:null,
      loader:null,
      border:null,
    },
    chairsData: {
      chairs: [],
      Interactive: [],
      Selected: [],
      DefaulMove: [0, -0],//Для Возможные смещений при отрисоке кресел
      zoneColors: [0x000000,0xF44336,0x2196F3],//заглушка, в mounted заменяется
    },
  }),
  computed: {
    ...mapGetters([
      "getProfileInfo",
      "getSeatColorPixiArr"
    ]),

  },
  mounted() {
    if(this.$el !== undefined && !this.flags.baseCreated){
      this.waiting()
    }
    this.$emit('onMountedPH')

    this.chairsData.zoneColors = this.getSeatColorPixiArr;
  },
  methods:{
    //Отслеживание первого размера зала > 0, для старта отрисовки
    waiting(){
      let resizeObserver = new ResizeObserver(() => {
        let _ww = document.getElementById('pixiHallContent').offsetWidth;
        let _hh = document.getElementById('pixiHallContent').offsetHeight - 100;

        if(_ww > 0 && _hh > 0 && !this.flags.baseCreated){
          this.emptyHallCreate(_ww,_hh)
        }
      });
      resizeObserver.observe(document.getElementById('pixiHallContent'));
    },

    //Создание пустого зала с loader
    emptyHallCreate(_ww,_hh){
      this.event_raw = hallBase;

      this.$emit('loadedStatus',"start");
      this.appPrint(_ww,_hh);
      this.viewportPrint("create",_ww,_hh);

      window.onresize = () => {
        this.sizeControl()
      }

      this.sizeControl()

      this.update()

      this.flags.viewportLoaded = true;
      this.flags.baseCreated = true;
    },

    //Переключатель видимости loader
    сastling(type) {
      switch (type) {
        case "load":
          // this.base.loader.alpha = 1;
          this.base.loader.alpha = 0;
          // this.$emit('onReadyToShow')
        break;
        case "loaded":
          this.base.loader.alpha = 0;
          break;
      }
    },

    //кручение loader
    rotateAnimation() {
      this.base.loader.rotation += 0.04;
      this.base.app.render(this.base.viewport)
    },

    //loader
    preLoader(_ww,_hh){
      this.base.loader = new Sprite(this.spritesTexture.preloader)

      this.base.loader.anchor.set(0.5);
      this.base.loader.width = 256;
      this.base.loader.height = 256;
      this.base.loader.x = _ww/2;
      this.base.loader.y = _hh/2;
      this.base.loader.alpha = 0;
      this.base.loader.id = 'loader';

      this.base.ticker.add(this.rotateAnimation)
      this.base.ticker.start();
      this.base.viewport.addChild(this.base.loader);
    },

    //Ресайз и центрирование
    sizeControl(type,_ww,_hh){
      switch(type){
        case "data_raw":
          console.log("coordinate sizeHall")
          console.log(type + "/" + _ww + "/" + _hh)
          break;
        default:
          _ww = document.getElementById('pixiHallContent').offsetWidth;
          _hh = document.getElementById('pixiHallContent').offsetHeight - 100;
      }

      this.base.app.resize(_ww, _hh)
      this.base.viewport.resize(_ww, _hh)

      this.containerToCenter()
    },

    //Центрирование
    containerToCenter(){
      this.base.viewport.fit();
      if(this.event_raw.b) {
        this.base.viewport.moveCenter(this.event_raw.data.layout.width / 2, this.event_raw.data.layout.height / 2)
      }else{
        let _ww = document.getElementById('pixiHallContent').offsetWidth;
        let _hh = document.getElementById('pixiHallContent').offsetHeight - 100;

        this.base.viewport.moveCenter(_ww / 2, _hh / 2)
      }
    },

    //Получение данных о зале из внешних компонентов
    createSeatData(data){
      this.event_raw = data;
      console.warn(this.event_raw)
      this.$emit('eventloaded', true);

      switch (this.flags.baseCreated) {
        case true:
          this.startToPrint("reCreate");
        break;
      }
    },

    // Очистка viewport от кресел/фона/border
    appClear(data, stepSize) {
      let addStep = 0;
      if (stepSize > 0) {
        addStep += stepSize;
      }
      if (data !== null) {
        data.children.splice(addStep, data.children.length);
      }
    },

    //Основная функция запуска/перезапуска отрисовки зала
    startToPrint(type){
      let _ww = document.getElementById('pixiHallContent').offsetWidth;
      let _hh = document.getElementById('pixiHallContent').offsetHeight - 100;
      if(_ww !== 0 || _hh !== 0){
        switch(this.flags.baseCreated){
          case true:
            switch(type) {
              case "pre":
                console.log("pre - под вопросом необходимости теперь")
              break;
              case "reCreate":
                this.base.viewport.alpha = 0;

                this.base.loader.x = this.base.viewport.width/2;
                this.base.loader.y = this.base.viewport.height/2;
                this.sizeControl();
                this.appClear(this.base.viewport,'1');
                this.сastling("load")
                this.base.viewport.alpha = 1;
              break;
            }
          break;
        }
        switch(type){
          case "create":
            if(this.event_raw.b && this.flags.viewportLoaded){
              setTimeout(() => {
                this.сastling("loaded")
                this.$emit('loadedStatus', "loaded");

                this.printSeatList();

                this.sizeFitter(_ww,_hh);
                this.$emit('onReadyToShow')
              }, 500)
            }
          break;
          case "reCreate":
            if(this.flags.baseCreated && this.flags.viewportLoaded){
              this.clearSelected("all")
              this.appClear(this.base.viewport,'2');
            }
            if (this.event_raw.b && this.flags.viewportLoaded) {
              setTimeout(() => {
                this.сastling("loaded")
                this.$emit('loadedStatus', "loaded");
                this.printSeatList();
                this.sizeFitter(_ww,_hh);
                this.$emit('onReadyToShow')
              }, 500)
            }
          break;
        }
      }
    },

    //Очистка выбранных кресел, с очисткой их за пределами PixiHall/локально снят выбор
    clearSelected(type) {
      switch (type) {
        case "all":
          //Сценарий только для стартовой отрисовки, так как убивает еще и интерактивные кресла текущего зала
          this.chairsData.Selected.splice(0, this.chairsData.Selected.length);
          this.chairsData.Interactive.splice(0, this.chairsData.Interactive.length)
          this.$emit('seatToOrder', this.chairsData.Selected);
          break;
        case "pixi":
          //Очищает только выбранные кресла внутри Pixi но не в других компонентах,
          //сценарий на случай, если из других мест выбранные кресла убиваются другими функциями
          this.chairsData.Selected.forEach((item) => {
            let finded = this.chairsData.Interactive.find(el => el.id === item.id)
            finded.alpha = 0.9;
            finded.scale.x -= 0.05;
            finded.scale.y -= 0.05;
            finded.tint = finded.originalColor;
          })
          this.chairsData.Selected.splice(0, this.chairsData.Selected.length);
          break;
      }
    },

    //create viewport
    viewportPrint(type,_ww,_hh){
      this.base.viewport = new Viewport({
        //https://davidfig.github.io/pixi-viewport/ - возможные опции с комментариями
        worldWidth: _ww,
        worldHeight: _hh,
        passiveWheel: false,
        interaction: this.base.app.plugins.interaction,
        disableOnContextMenu: true,
      });

      this.base.viewport
          .drag()
          .pinch({
            noDrag: true,
          })
          .wheel()
          .decelerate()

      this.preLoader(_ww,_hh);
      this.border();

      this.base.viewport.fit()
      this.base.viewport.moveCenter(_ww / 2, _hh / 2)

    },

    //create Renderer
    appPrint(_ww,_hh){
      this.base.app = new Renderer({
        antialias: true,
        autoDensity: true,
        width: _ww,
        height: _hh,
        backgroundAlpha: 0,
        resolution: window.devicePixelRatio || 1,
      });
      this.$el.appendChild(this.base.app.view);
      this.flags.baseCreated = true;
    },

    //функция из демо версии, "dirty - должна ли она отображаться на экране из-за изменения"
    //TODO проверить необходимость после смены главного App с Aplication на Renderer (больше касается мобильной версии и сброса событий нажатых пальцев за пределами экрана)
    update() {
      if (this.base.viewport.dirty) {
        this.base.app.render(this.base.viewport)
        this.base.viewport.dirty = false
      }
      requestAnimationFrame(() => this.update())
    },

    //рамка, пока что рисуется только для пустого зала, потом стирается безвозвратно (при вызове appClear, можно увеличить с какого элемента очищать, сейчас border - второй элемент после loader)
    border() {
      this.base.border = new Graphics();
      this.base.border.lineStyle(10, 0xff0000).drawRect(0, 0, this.base.viewport.worldWidth, this.base.viewport.worldHeight)
      this.base.viewport.addChild(this.base.border);
    },

    //Отрисовка фона и мест
    printSeatList() {
      this.backgroundPrint();

      this.event_raw.data.seats.forEach((item)=>{
        let chairImg = item.isFree ? this.spritesTexture.ChairEmpty : this.spritesTexture.ChairSale2;
        let t = new Sprite(chairImg);

        t.anchor.set(0.5);
        t.width = this.event_raw.data.layout.seat_width;
        t.height = this.event_raw.data.layout.seat_height;
        t.x = item.left + this.chairsData.DefaulMove[0];
        t.y = item.top + this.chairsData.DefaulMove[1];
        t.rotation = item.rotate > 0 ? (Math.PI * item.rotate) / 180 : 0;

        t.id = item.id;
        t.alpha = 0.9;

        t.zone = item.zone;
        t.interactive = item.isFree;
        t.price = this.priceFind(item.zone);

        t.tint = this.chairsData.zoneColors[item.zone]
        t.originalColor = this.chairsData.zoneColors[item.zone];

        t.seatData = {x: t.x, y: t.y, row: item.row, chair: item.chair, price: t.price}

        t.on('pointerover', () => {
          let checkStat = this.checkerSeat(t.id);
          switch (checkStat[0]) {
            case false:
              this.textInfoData(true, t.seatData)
              t.alpha = 0.7;
              break;
          }
        })
        t.on("pointerout", () => {
          let checkStat = this.checkerSeat(t.id);
          switch (checkStat[0]) {
            case false:
              this.textInfoData(false)
              t.alpha = 0.9;
              break;
          }
        })

        t.on('pointerdown', () => {
          this.SeatClickTouch(t, "pointerdown");
          this.textInfoData(false)
        });

        // "ко всем отрисованным креслам" можно будет после отрисовки обращатся по ID , и они будут реактивно меняться (сейчас нигде не применяется)
        //TODO Если весь список кресел включая проданные излишнее хранение данных - можно будет отключить, и пользоваться только списком с интерактивными креслами
        this.chairsData.chairs.push(t);

        // "ко всем отрисованным креслам которые еще не проданы" можно будет после отрисовки обращатся по ID , и они будут реактивно меняться
        if (t.interactive) {
          this.chairsData.Interactive.push(t)
        }


        this.base.viewport.addChild(t);
      })
      this.textInfo();
    },

    //Поиск цены при отрисовке кресла
    priceFind(zone) {
      return this.event_raw.data.prices.find(el => el.zone === zone).price;
    },

    //События по клику на кресло
    SeatClickTouch(t, eventType) {
      let last_seat = {
        id: t.id,
        selected: false, // было выделено, или снято
      };

      //определяем какой фильтр к креслу применить в зависимости от ситуации на текущий момент
      let seatInfo = {id: t.id, price: t.price, zone: t.zone}
      let checkStat = this.checkerSeat(t.id);
      switch (eventType) {
        case "pointerdown":
          switch (checkStat[0]) {
            case true:
              this.chairsData.Selected.splice(checkStat[2], 1);
              t.scale.x -= 0.05;
              t.scale.y -= 0.05;
              t.tint = t.originalColor;
              t.alpha = 0.9;
              break;
            default:
              this.chairsData.Selected.push(seatInfo);
              t.scale.x += 0.05;
              t.scale.y += 0.05;
              t.tint = 0xffd700;
              t.alpha = 1;
              last_seat.selected = true;
          }
          this.$emit('seatToOrder', this.chairsData.Selected, last_seat);
        break;
      }
    },

    //проверка выделенности кресла
    checkerSeat(id) {
      //проверка выбранных мест, что бы не перебивать эффект выделения
      let searchActiveSeat = this.chairsData.Selected.findIndex(el => el.id === id);
      let selected = [false, id, searchActiveSeat];
      switch (searchActiveSeat) {
        case -1:
          selected[0] = false;
          break;
        default:
          selected[0] = true;
      }
      return selected;
    },

    //Подсветка ценовых зон
    seatColorZone(data) {
      if (data === "all") {
        this.chairsData.Interactive.forEach((item) => {
          let selected = this.chairsData.Selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          item.tint = this.chairsData.zoneColors[item.zone]
        })
      } else {
        this.chairsData.Interactive.forEach((item) => {
          let selected = this.chairsData.Selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          if (item.zone !== data) {
            item.tint = 0x333333;
          } else {
            item.tint = this.chairsData.zoneColors[item.zone]
          }
        })
      }
    },

    //Отрисовка бекграунда зала
    backgroundPrint() {
      let path = "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/";

      // темная тема
      let part1 = (this.event_raw.data.layout.background.substring(0, this.event_raw.data.layout.background.length - 4));
      let part2 = "png";
      let bg = path + part1 + 'b.' + part2;

      this.spritesTexture.background = Texture.from(bg)
      let back = new Sprite(this.spritesTexture.background)

      back.anchor.set(0.018); //документация https://pixijs.download/dev/docs/PIXI.Sprite.html
      back.x = 0;
      back.y = -5;
      back.interactive = false;

      this.base.viewport.addChild(back)
    },

    //Создание текстовой подсказки
    textInfo() {
      let style = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fill: '#ffffff',
        wordWrap: true,
        wordWrapWidth: 180,
        lineJoin: 'round',
      });

      let {textInfo, textBack} = this.base;
      textInfo = new Text('', style);
      textInfo.id = 'seatText';

      textBack = new Graphics();

      textBack.lineStyle(2, 0xCCCCCC, 0.25);
      textBack.beginFill(0x000000);
      textBack.drawRoundedRect(0, 0, 180, 44, 16);
      textBack.alpha = 0;
      textBack.endFill();
      textBack.id = "textBack";

      this.base.viewport.addChild(textBack);
      this.base.viewport.addChild(textInfo);
    },

    //Изменение текстовой подсказки
    textInfoData(flag,data){
      let seat = this.base.viewport.children.find(el=>el.id === "seatText");
      let seatBack = this.base.viewport.children.find(el=>el.id === "textBack");
      switch(flag){
        case true:
          seat.text = data.row + " ряд, " + data.chair + " место. \nЦена: " + data.price + " руб.";
          seat.alpha = 1;
          seat.x = data.x;
          seat.y = data.y + 35;
          seat.anchor.set(0.5);

          seatBack.alpha = 0.6;
          seatBack.x = data.x - seatBack.width / 2;
          seatBack.y = data.y + 14;
          break;
        case false:
          seat.alpha = 0;
          seatBack.alpha = 0;
          seat.text = '';
          break;
      }
    },

    //проба ресайза по принципу меньшей из сторон
    sizeFitter(ww,hh){
      if(ww>hh){
        this.base.viewport.fitHeight(hh,true);
      }else{
        this.base.viewport.fitWidth(ww,true);
      }
    },

    //Блокировка прокрутки страницы при зуме колесиком
    scroll(e) {
      e = e || window.event;
      if (e.preventDefault)
        e.preventDefault();
      e.returnValue = false;
    },
  },
}


</script>

<style scoped>


</style>
